  import fleekStorage from "@fleekhq/fleek-storage-js";
  const API_KEY = "0/RIQbbIouKs38Lih5nHpA=="
  const API_SECRET = "m0PHGT4OsH8LFsbxYHjWsy01nk91ZOALHksc8dvtP1g="
  const BUCKET = "a9d4c3dc-5fec-4637-a586-0f1aa7c6c20e-bucket"
  const domain = "storage.virtualtryon.tech"
  class FileUploadService {
    async getFiles() {
      const files = await fleekStorage.listFiles({
        apiKey: API_KEY,
        apiSecret: API_SECRET,
        bucket: BUCKET,
        domain: domain,
        getOptions: [
          'key', 'hash', 'publicUrl'
        ]
      })
      return files
    }
  }

  export default new FileUploadService();