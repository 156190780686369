import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import './Store.css';
import axios from 'axios';
import { Alert, AlertTitle } from '@material-ui/lab';



//const base_url = "http://localhost:5000";
const base_url="https://api.virtualtryon.tech";

const Store = () => {

  const [stores, setStores] = useState([]);
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  let columns = [
    { title: 'Add Store Name', field: 'name' },
    { title: 'Assign Code', field: 'code' },
  ]

  useEffect(() => {
    axios.get(`${base_url}/stores`)
      .then(res => {
        const stores = res.data;
        setStores(stores);
        // console.log(users);
      })
  }, [])



  //function for updating the existing row details
  const handleRowUpdate = (newData, oldData, resolve) => {
    //validating the data inputs
    let errorList = []
    if (newData.name === "") {
      errorList.push("Try Again, You didn't enter the name field")
    }
    if (newData.code === "") {
      errorList.push("Try Again, You didn't enter the Username field")
    }

    if (errorList.length < 1) {
      const index = oldData.tableData.id;
      let _id = stores[index]._id;
      axios.put(`${base_url}/stores/${_id}`, newData)
        .then(response => {
          const updateUser = [...stores];
          //  const index = oldData.tableData.id;
          updateUser[index] = newData;
          setStores([...updateUser]);
          resolve()
          setIserror(false)
          setErrorMessages([])
        })
        .catch(error => {
          setErrorMessages(["Update failed! Server error"])
          setIserror(true)
          resolve()

        })
    } else {
      setErrorMessages(errorList)
      setIserror(true)
      resolve()

    }
  }


  //function for deleting a row
  const handleRowDelete = (oldData, resolve) => {
    console.log('old data', oldData);
    const index = oldData.tableData.id;
    let _id = stores[index]._id;
    axios.delete(`${base_url}/stores/${_id}`)
      .then(response => {
        const dataDelete = [...stores];
        //const index = oldData.tableData.id;
        dataDelete.splice(index, 1);
        setStores([...dataDelete]);
        resolve()
      })
      .catch(error => {
        setErrorMessages(["Delete failed! Server error"])
        setIserror(true)
        resolve()
      })
  }


  //function for adding a new row to the table
  const handleRowAdd = (newData, resolve) => {
    //validating the data inputs
    let errorList = []
    if (newData.name === "") {
      errorList.push("Try Again, You didn't enter the name field")
    }
    if (newData.code === "") {
      errorList.push("Try Again, You didn't enter the Username field")
    }

    if (errorList.length < 1) {
      axios.post(`${base_url}/stores`, newData)
        .then(response => {
          let newUserdata = [...stores];
          newUserdata.push(newData);
          setStores(newUserdata);
          resolve()
          setErrorMessages([])
          setIserror(false)
        })
        .catch(error => {
          setErrorMessages(["Cannot add data. Server error!"])
          setIserror(true)
          resolve()
        })
    } else {
      setErrorMessages(errorList)
      setIserror(true)
      resolve()
    }
  }


  return (
    <div className="Store">

      <nav class="navbar">
        <ul>
          <li><a href="/Store">OCHO stores</a></li>
          <li><a href="/FileUpload">Uploading Files</a></li>
        </ul>
      </nav>

      <MaterialTable
        title="Store Details"
        columns={columns}
        data={stores}
        options={{
          headerStyle: { borderBottomColor: 'red', borderBottomWidth: '3px', fontFamily: 'verdana' },
          actionsColumnIndex: -1
        }}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              handleRowUpdate(newData, oldData, resolve);

            }),
          onRowAdd: (newData) =>
            new Promise((resolve) => {
              handleRowAdd(newData, resolve)
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              handleRowDelete(oldData, resolve)
            }),
        }}
      />

      <div>
        {iserror &&
          <Alert severity="error">
            <AlertTitle>ERROR</AlertTitle>
            {errorMessages.map((msg, i) => {
              return <div key={i}>{msg}</div>
            })}
          </Alert>
        }
      </div>

    </div>
  );
}

export default Store;