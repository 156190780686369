import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';
import { Signin, Signup, FileUpload, Store } from './components';


export default function App() {
  const [user, setLoginUser] = useState({})
  useEffect(() => {
    return () => {
      const str = localStorage.getItem("user")
      setLoginUser(str ? JSON.parse(str) : {})
    }
  }, [])

  const updateUser = (user) => {
    localStorage.setItem("user", JSON.stringify(user))
    setLoginUser(user)
  }



  return (
    <div style={{ overflow: "auto" }}>

      <Router>
        <Routes>
          <Route exact path="/" element={
            user && user._id ? <Store /> : <Signin updateUser={updateUser} />
          } />
          <Route path="/Signin" element={<Signin />} />
          <Route path="/Signup" element={<Signup />} />
          <Route path="/FileUpload" element={<FileUpload />} />
          <Route path="/Store" element={<Store />} />

        </Routes>
      </Router>

    </div>
  )
}