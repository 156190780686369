import React, { Component } from "react";
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import CssBaseline from '@mui/material/CssBaseline';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { green, pink } from '@mui/material/colors';
import UploadService from "../services/file-upload.service";
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import LinearProgressWithLabel from "./LinearProgressWithLabel";
import fleekStorage from "@fleekhq/fleek-storage-js";
import { Avatar, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Step, StepLabel, Stepper, TextField } from "@mui/material";
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import axios from "axios";
import { Buffer } from 'buffer';


//const base_url="http://localhost:5000";
const base_url = "https://api.virtualtryon.tech";

const API_KEY = "0/RIQbbIouKs38Lih5nHpA=="
const API_SECRET = "m0PHGT4OsH8LFsbxYHjWsy01nk91ZOALHksc8dvtP1g="
const BUCKET = "a9d4c3dc-5fec-4637-a586-0f1aa7c6c20e-bucket"

const steps = ['Choose Files', 'Fill information', 'Review and submit'];
function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];



export default class FileUpload extends Component {

    constructor(props) {
        super(props);
        this.selectFiles = this.selectFiles.bind(this);
        this.chooseImage = this.chooseImage.bind(this);
        this.handleNext = this.handleNext.bind(this)
        this.handlePrevious = this.handlePrevious.bind(this)

        this.state = {
            selectedFiles: undefined,
            previewImages: [],
            progressInfos: [],
            message: [],
            fileInfos: [],
            urlInfos: [],
            activeStep: 0
        };
    }

    componentDidMount() {
    }

    handleNext() {
        const { urlInfos, colorInfos,  skuInfos, imageUrls } = this.state
        if (this.state.activeStep < 2) {
            let fulfilled = true;
            for (let i = 0; i < urlInfos.length; i++) {
                if (!urlInfos[i] || !colorInfos[i] || !skuInfos[i] || !imageUrls[i]) fulfilled = false;
            }
            console.log(fulfilled)
            if (fulfilled)
                this.setState({ activeStep: this.state.activeStep + 1 })
        }
        else {

            const promises = urlInfos.map(async (url, index) => {
                const ret = await axios.post(`${base_url}/sku`, { sku: skuInfos[index], data: url, color: colorInfos[index], image: imageUrls[index] });
                return ret
            })
            Promise.all(promises).then(data => {
                this.setState({ activeStep: 3 })
                const status = new Array(data.length);
                for (let i = 0; i < data.length; i++) {
                    try {
                        if (data[i].data.message === "success") status[i] = true;
                        else status[i] = false
                    } catch (e) {
                        status[i] = false;
                    }
                }
                this.setState({ uploadStatus: status })
            })
        }
    }
    handlePrevious() {
        this.setState({ activeStep: 1 })
    }

    selectFiles(event) {
        // let images = [];
        const length = event.target.files.length;
        const files = event.target.files;
        const progress = new Array(length);
        const fileInfos = new Array(length);
        const urlInfos = new Array(length);
        const skuInfos = new Array(length);
        const colorInfos = new Array(length);
        const imageProgress = new Array(length);
        const imageUrls = new Array(length);
        
        this.setState({ skuInfos, colorInfos, imageProgress, imageUrls })
        let uploadedCount = 0;

        UploadService.getFiles().then(data => {
            const key = data.length;
            for (let i = 0; i < length; i++) {
                const file = files[i]
                fileInfos[i] = file.name
                const reader = new window.FileReader()
                reader.readAsArrayBuffer(file)
                reader.onloadend = async () => {
                    const buffer = Buffer.from(reader.result)
                    let uploadedFile
                    try {
                        uploadedFile = await fleekStorage.upload({
                            apiKey: API_KEY,
                            apiSecret: API_SECRET,
                            bucket: BUCKET,
                            domain: "storage.virtualtryon.tech",
                            key: fileInfos[i].toString(),
                            data: buffer,
                            httpUploadProgressCallback: (e) => {
                                progress[i] = Math.round(e.loaded / e.total * 100);
                                this.setState({ progressInfos: progress })
                            }
                        })
                    } catch (e) {
                        console.log(e)
                    }
                    const index = i;
                    urlInfos[index] = uploadedFile.publicUrl
                    uploadedCount += 1;
                    if (uploadedCount === length) this.setState({ activeStep: 1 });
                    this.setState({ urlInfos })
                }
            }
            this.setState({
                fileInfos
            });
        })
    }
    chooseImage(event, index) {
        const file = event.target.files[0]
        UploadService.getFiles().then(data => {
            const key = data.length;
            const Img = file.name
            const reader = new window.FileReader()
            reader.readAsArrayBuffer(file)
            reader.onloadend = async () => {
                const buffer = Buffer.from(reader.result)
                let uploadedFile
                try {
                    const imageProgress = this.state.imageProgress
                    uploadedFile = await fleekStorage.upload({
                        apiKey: API_KEY,
                        apiSecret: API_SECRET,
                        bucket: BUCKET,
                        domain: "storage.virtualtryon.tech",
                        key: Img.toString(),
                        data: buffer,
                        httpUploadProgressCallback: (e) => {
                            imageProgress[index] = Math.round(e.loaded / e.total * 100);
                            this.setState({ progressInfos: imageProgress })
                        }
                    })
                } catch (e) {
                    console.log(e)
                }
                const imageUrls = this.state.imageUrls
                imageUrls[index] = uploadedFile.publicUrl
                this.setState({ imageUrls: imageUrls })
            }
        })
    }

    handleChange(e, index, field) {
        const { skuInfos,  colorInfos } = this.state;
        if (field === "sku")
            skuInfos[index] = e.target.value;
        if (field === "color")
            colorInfos[index] = e.target.value;
        this.setState({ skuInfos,  colorInfos })
    }
    render() {
        const { progressInfos, fileInfos, activeStep, urlInfos, imageProgress, imageUrls } = this.state;
        const theme = createTheme();
        return (
            <ThemeProvider theme={theme}>
                <AppBar position="relative">
                        <Toolbar>
                            <CameraIcon sx={{ mr: 2 }} />
                            <Typography variant="h6" color="inherit" noWrap>
                                OCHO
                            </Typography>
                        </Toolbar>
                    </AppBar>
                <Grid container component="main" sx={{ height: '100vh' }} justifyContent="center">
                    <CssBaseline />
                    
                    <main>
                        {/* Hero unit */}
                        <Box
                            sx={{
                                bgcolor: 'background.paper',
                                pt: 8,
                                pb: 6,
                            }}
                        >
                            <Container maxWidth="sm">
                                <Typography
                                    component="h1"
                                    variant="h2"
                                    align="center"
                                    color="#e91e63"
                                    gutterBottom
                                >
                                    OCHO
                                </Typography>
                                <Typography variant="h5" align="center" color="#2196f3" paragraph>
                                    OCHO provides people  with clear and beautiful glasses.
                                </Typography>
                                <Stepper activeStep={activeStep}>
                                    {steps.map((label, index) => {
                                        const stepProps = {};
                                        const labelProps = {};
                                        return (
                                            <Step key={label} {...stepProps}>
                                                <StepLabel {...labelProps}>{label}</StepLabel>
                                            </Step>
                                        );
                                    })}
                                </Stepper>

                                {activeStep === 0 && <Stack
                                    sx={{ py: 4 }}
                                    direction="row"
                                    spacing={2}
                                    justifyContent="center"
                                >
                                    <label htmlFor="upload">
                                        <Typography sx={{ backgroundColor: "#ce93d8", cursor: "pointer", paddingX: 3, paddingY: 1.5 }} variant="button">Choose Files</Typography>

                                        <input id="upload" type="file" style={{ display: "none" }} multiple accept="image/" onChange={this.selectFiles} />
                                    </label>
                                </Stack>}
                                {fileInfos &&
                                    <List
                                        sx={{
                                            marginY: 3,
                                            width: '100%',
                                            flexGrow: 1
                                        }}
                                    >
                                        {fileInfos.map((image, index) => (
                                            <div key={index}>
                                                <ListItem sx={{ display: 'flex', alignItems: 'flex-start' }}>
                                                    <ListItemAvatar>
                                                        <Avatar sx={this.state.uploadStatus && { backgroundColor: this.state.activeStep === 3 && this.state.uploadStatus[index] ? green[500] : pink[500] }}>
                                                            <LabelImportantIcon />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <Box display="flex" flexDirection="column" flexGrow={1} >
                                                        <ListItemText primary={image} secondary={urlInfos[index] ?? ""} />
                                                        {progressInfos[index] > 0 && progressInfos[index] < 100 && <Box sm={{ width: "100%" }}>
                                                            <LinearProgressWithLabel value={progressInfos[index]} />
                                                        </Box>}
                                                        {activeStep > 0 &&
                                                            <Box display="flex" justifyContent="center" gap={4}>
                                                                <Box display="flex" flexDirection="column" justifyContent="center" align="center" paragraph>
                                                                  
                                                                    <TextField
                                                                        InputProps={{
                                                                            readOnly: activeStep === 2,
                                                                        }}
                                                                        label="Input SKU name"
                                                                        variant="filled"
                                                                        onChange={(e) => this.handleChange(e, index, "sku")}
                                                                    />
                                                                    <TextField
                                                                        InputProps={{
                                                                            readOnly: activeStep === 2,
                                                                        }}
                                                                        label="Input color name"
                                                                        variant="filled"
                                                                        onChange={(e) => this.handleChange(e, index, "color")}
                                                                    />
                                        
                                                                </Box>

                                                                <Box sx={{ aspectRatio: "1", border: "2px solid #1976d2", borderStyle: "dashed" }} borderRadius={8} display="flex" gap={1}>
                                                                    <label style={{ width: "100%" }} htmlFor={`uploadImage${index}`}>
                                                                        <Box sx={{ cursor: "pointer", borderRadius: 8 }} width="100%" height='100%' maxWidth="168px" p={1} maxHeight="168px" display="flex" justifyContent="center" alignItems="center">
                                                                            {imageUrls[index] ? <img style={{ width: "100%" }} src={imageUrls[index]} alt='' /> : imageProgress[index] > 0 ? <Typography>{imageProgress[index]}%</Typography> : <Typography align="center" paragraph>Choose Image</Typography>}
                                                                        </Box>
                                                                        <input id={`uploadImage${index}`} type="file" style={{ display: "none" }} accept="image/" onChange={(e) => this.chooseImage(e, index)} />

                                                                    </label>

                                                                </Box>


                                                            </Box>
                                                        }
                                                    </Box>
                                                </ListItem>
                                                <Divider variant="inset" component="li" />

                                            </div>

                                        ))}

                                    </List>}

                            </Container>
                        </Box>
                        {activeStep > 0 && <Container maxWidth="flex">
                            <Box display="flex" width="100%" justifyContent="space-between">
                                {activeStep === 2 && <Button variant="contained" onClick={this.handlePrevious}>Previous</Button>}
                                <div></div>
                                <Button variant="contained" onClick={this.handleNext}>{activeStep < 2 ? "Next" : "Submit"}</Button>
                            </Box>

                        </Container>}
                        <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
                            <Typography variant="h6" align="center" gutterBottom>
                                OCHO
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                align="center"
                                color="text.secondary"
                                component="p"
                            >
                                Something here to give the OCHO a purpose!
                            </Typography>
                            <Copyright />
                        </Box>
                    </main>
                    {/* Footer */}

                    {/* End footer */}
                </Grid>
            </ThemeProvider>
        );
    }
}